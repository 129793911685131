import { Injectable, WritableSignal, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  EmployeeClient,
  EmployeeV3ForCardDto,
  EmployeeV3ForCardResponseDto,
  EmployeeV3PositionItemDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';
import { WebSocketClient, WsServerPayloadEnum } from '@data-access/bulk-operations-ws';
import { getActiveTimelineItem } from '@em-helpers';
import { BehaviorSubject, Observable, Subject, filter, retry } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { EnumEmployeeCardState } from './employee-card-state.enum';

@Injectable({
  providedIn: 'root',
})
export class SelectedEmployeeService {
  private readonly employeeIdSignal: WritableSignal<string> = signal(undefined);
  private readonly positionIdSignal: WritableSignal<string> = signal(undefined);
  private readonly employeeNumberSignal: WritableSignal<string> = signal(undefined);
  private readonly positionIndexSignal: WritableSignal<number> = signal(undefined);

  public readonly employeeId = this.employeeIdSignal.asReadonly();
  public readonly positionId = this.positionIdSignal.asReadonly();
  public readonly employeeNumber = this.employeeNumberSignal.asReadonly();
  public readonly positionIndex = this.positionIndexSignal.asReadonly();

  private readonly employeeSignal: WritableSignal<EmployeeV3ForCardDto> = signal(undefined);
  private readonly employee$ = toObservable(this.employeeSignal);
  private readonly positionSignal: WritableSignal<EmployeeV3PositionItemDto> = signal(undefined);
  private readonly position$ = toObservable(this.positionSignal);
  private readonly stateSubject = new BehaviorSubject<EnumEmployeeCardState>(
    EnumEmployeeCardState.Loading,
  );
  private readonly state$ = this.stateSubject.asObservable();

  public readonly employee = this.employeeSignal.asReadonly();
  public readonly position = this.positionSignal.asReadonly();

  // Used to cancel old request when calling in sequence.
  private readonly fetchEmployeeSubject = new Subject<string>();

  constructor(
    private employeeClient: EmployeeClient,
    private webSocketClient: WebSocketClient,
  ) {
    this.subscribeToFetchEmployee();
    this.subscribeToWebSocket();
  }

  /*
   * @deprecated This method is deprecated. Use signal instead.
   */
  public getEmployee(): Observable<EmployeeV3ForCardDto> {
    return this.employee$.pipe(filter((employee) => !!employee));
  }

  /*
   * @deprecated This method is deprecated. Use signal instead.
   */
  public getPosition(): Observable<EmployeeV3PositionItemDto> {
    return this.position$.pipe(filter((position) => !!position));
  }

  /*
   * @deprecated This method is deprecated. Use signal instead.
   */
  public getState(): Observable<EnumEmployeeCardState> {
    return this.state$;
  }

  public setActivePosition(positionId: string): void {
    this.stateSubject.next(EnumEmployeeCardState.Loading);
    const position = this.employee().positionTimeline.find((item) => item.value.id === positionId);

    if (position) {
      this.positionIdSignal.set(positionId);
      this.positionSignal.set(position);
    }
    this.stateSubject.next(EnumEmployeeCardState.Ready);
  }

  public fetchEmployee(employeeId: string): void {
    this.fetchEmployeeSubject.next(employeeId);
  }

  public cleanupEmployee(): void {
    this.employeeIdSignal.set(undefined);
    this.positionIdSignal.set(undefined);
    this.employeeNumberSignal.set(undefined);
    this.positionIndexSignal.set(undefined);
    this.employeeSignal.set(undefined);
    this.positionSignal.set(undefined);
  }

  private subscribeToWebSocket() {
    this.webSocketClient
      .getMessagesByType(WsServerPayloadEnum.IcUpserted)
      .pipe(filter((msg) => msg.type === IcLookupTypeEnumDto.Employee))
      .subscribe((msg) => {
        const icUpsertedEmployeeIds = [
          ...msg.employeeItems.map((item) => item.id),
          ...msg.positionItems.map((item) => item.employeeId),
        ];

        // If the current employee is not in the list of updated employees, do nothing
        if (!this.employeeIdSignal() || !icUpsertedEmployeeIds.includes(this.employeeIdSignal())) {
          return;
        }

        this.fetchEmployee(this.employeeIdSignal());
      });

    this.webSocketClient
      .getMessagesByType(WsServerPayloadEnum.EmployeeCardEditActionCompleted)
      .pipe(filter((msg) => !!msg.errorMessage))
      .subscribe((msg) => {
        // If the current employee is not in the list of updated employees, do nothing
        if (!this.employeeIdSignal() || this.employeeIdSignal() !== msg.employeeId) {
          return;
        }

        this.fetchEmployee(this.employeeIdSignal());
      });
  }

  private subscribeToFetchEmployee(): void {
    this.fetchEmployeeSubject
      .pipe(
        tap(() => this.stateSubject.next(EnumEmployeeCardState.Loading)),
        switchMap((employeeId) =>
          this.employeeClient.getEmployee(employeeId).pipe(retry(3), take(1)),
        ),
      )
      .subscribe({
        next: this.onNextGetEmployeeV3.bind(this),
        error: this.onErrorGetEmployeeV3.bind(this),
      });
  }

  private onNextGetEmployeeV3(result: EmployeeV3ForCardResponseDto) {
    this.employeeSignal.set(result.employee);
    this.employeeIdSignal.set(result.employee.id);
    this.employeeNumberSignal.set(result.employee.customId);

    // TODO: add handling when no position is currently active
    let positionIndex = result.employee.positionTimeline.findIndex(
      (item) => item === getActiveTimelineItem(result.employee.positionTimeline),
    );

    if (positionIndex === -1) {
      positionIndex = result.employee.positionTimeline.length - 1;
    }

    const position = result.employee.positionTimeline[positionIndex];

    this.positionSignal.set(position);
    this.positionIdSignal.set(position.value.id);
    this.positionIndexSignal.set(positionIndex + 1);

    this.stateSubject.next(EnumEmployeeCardState.Ready);
  }

  private onErrorGetEmployeeV3(err: any) {
    this.stateSubject.next(EnumEmployeeCardState.Error);
    throw err;
  }
}
